import { memo } from 'react';
import loadable from '@loadable/component';

import * as Meta from '@Queries/Meta';
import { useDevPanel } from '@Stores/DevPanel';
import useRequest from '@Hooks/useRequest';
import getCookie from '@Utils/getCookie';
import ErrorBoundary from '@Components/ErrorBoundary';

import type { FC, ReactChild } from 'react';
import type { CountryCode } from '@Types/Base';
import type { PopupData } from '@Promo/typings';

interface LoaderProps {
  country: CountryCode;
  children: (module: Module) => ReactChild;
}

interface Module {
  default: PopupData[];
}

export interface PopupsProps {
  slug: string;
  render: (popups: PopupData) => ReactChild;
}

const Loader = loadable.lib<LoaderProps, FC<LoaderProps>>(
  (props) => import(`@Promo/popups/${props.country}.ts`),
);

const Popups: FC<PopupsProps> = (props) => {
  const { slug, render } = props;
  const { country } = useRequest();
  const { currentDate } = useDevPanel();
  const meta = Meta.useMeta();

  return (
    <ErrorBoundary>
      <Loader country={country}>
        {(module: Module) => {
          const popups = module.default || [];

          const filteredPopups = popups.filter((popup) => {
            if (popup.startDate > currentDate || popup.endDate < currentDate) return false;
            // Определяем, закрывался ли попап ранее
            if (getCookie(popup.cookieKey)) return false;
            // Проверяем, что вызов происходит со страницы, определенной в настройках
            if (!popup.categoriesContain.includes(slug)) return false;

            // Фильтруем по регионам
            if (meta.isPending) return false;
            if (popup.regionIds && !popup.regionIds.includes(meta.data.region.id)) return false;

            // Исключаем регионы
            if (popup.excludeRegionIds && popup.excludeRegionIds.includes(meta.data.region.id)) {
              return false;
            }

            return true;
          });
          const [popup] = filteredPopups;
          return render(popup);
        }}
      </Loader>
    </ErrorBoundary>
  );
};

export default memo(Popups);
