import { memo, useState, useCallback, useMemo } from 'react';
import { cn, GalleryWithProgress, NavArrows } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import useCountries from '@Hooks/useCountries';
import Section from '@Components/Section';
import CardInView from '@Components/CardInView';
import PromoCard from '@Components/PromoCard';
import styles from './Recommendations.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { ProgressOptions } from '@divlab/divanui';
import type { Promotion, CardBannerData } from '@Promo/typings';

export interface RecommendationsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  promotions?: Promotion<CardBannerData>[];
}

const Recommendations: FC<RecommendationsProps> = (props) => {
  const { className, promotions } = props;
  const { t } = useTranslation();
  const { isBlr } = useCountries();
  const [slideIndex, setSlideIndex] = useState(0);
  const [track, setTrack] = useState<ProgressOptions>(null);

  const cards = useMemo(() => {
    return promotions.reduce<CardBannerData[]>((prev, current) => {
      // По требованию SEO добавляем ко всем изображениям на главной странице на divan.by атрибуты alt
      const newCard = current.materials.map((material) => {
        return {
          ...material,
          resources: {
            ...material.resources,
            default: {
              ...material.resources.default,
              alt: isBlr ? material.text.title : null,
            },
          },
        };
      });
      return [...prev, ...newCard];
    }, []);
  }, [promotions, isBlr]);

  const normalizeSlide = useCallback(
    (index: number) => {
      if (index < 0) return 0;
      if (index > cards.length) return cards.length;

      return index;
    },
    [cards.length],
  );

  const handleChangeProgress = useCallback((opts: ProgressOptions) => {
    setTrack(opts);
  }, []);

  const handleChangeCurrent = useCallback(({ current }) => {
    setSlideIndex(current);
  }, []);

  const handlePrev = useCallback(() => {
    setSlideIndex((prev) => normalizeSlide(prev - 1));
  }, [normalizeSlide]);

  const handleNext = useCallback(() => {
    if (track && track.finished) return;

    setSlideIndex((prev) => normalizeSlide(prev + 1));
  }, [normalizeSlide, track]);

  return (
    <div className={cn(className, styles.wrapper)}>
      <Section
        typeSection={isBlr ? 'universal' : 'secondary'}
        title={t('recommendations')}
        arrows={
          track?.width < 100 && (
            <div>
              <NavArrows onPrev={handlePrev} onNext={handleNext} />
            </div>
          )
        }
      >
        <GalleryWithProgress
          className={styles.galleryWrapper}
          cnGallery={styles.gallery}
          cnTrack={styles.track}
          slideIndex={slideIndex}
          onChangeProgress={handleChangeProgress}
          onChangeCurrent={handleChangeCurrent}
        >
          {cards.map((card, index) => (
            <div key={card.id}>
              <CardInView
                listTitle={t('recommendations')}
                position={index}
                card={card}
                cardType='promo'
                id={card.text?.title}
                targetPathname={card.link}
              >
                <PromoCard card={card} className={styles.card} />
              </CardInView>
            </div>
          ))}
        </GalleryWithProgress>
      </Section>
    </div>
  );
};

export default memo(Recommendations);
