import { Button, cn } from '@divlab/divanui';

import useDeps from '@Contexts/DI/useDeps';
import useTranslation from '@Queries/useTranslation';
import Link from '@Navigation/Link';
import carImg from './car.svg';
import styles from './Header.module.css';

import type { FC, HTMLAttributes } from 'react';

interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  mainLink: string;
}

const Header: FC<HeaderProps> = (props) => {
  const { className, mainLink, ...restProps } = props;
  const { t } = useTranslation();
  const { analytics } = useDeps();
  const handleClick = () => {
    analytics.dispatchEvent('promo.seeAll', {
      category: 'Variants_all',
      action: 'show',
      label: 'MainSale',
    });
  };

  return (
    <div {...restProps} className={cn(styles.header, className)}>
      <div className={styles.leftSideContainer}>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>{t('express-delivery')}</h2>
          <p className={styles.text}>{t('dont-have-to-vait')}</p>
        </div>
        <Link to={mainLink}>
          <Button onClick={handleClick} className={styles.button}>
            {t('see-all')}
          </Button>
        </Link>
      </div>
      <img src={carImg} className={styles.img} />
    </div>
  );
};

export default Header;
