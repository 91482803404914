import { cn } from '@divlab/divanui';

import Link from '@Navigation/Link';
import Img from '@UI/Img';
import SaleSticker from '@Components/SaleSticker';
import CardInView from '@Components/CardInView';
import styles from './Card.module.css';

import type { FC } from 'react';
import type { LinkProps } from '@divlab/divanui';
import type { CategoriesInStockData } from '@Pages/PageIndex/typings';

interface CardProps extends Omit<LinkProps, 'to'> {
  card: CategoriesInStockData;
  idx: number;
  className?: string;
}

const Card: FC<CardProps> = (props) => {
  const { card, idx, className, ...restProps } = props;

  return (
    <Link {...restProps} className={cn(styles.link, className)} to={card.url}>
      <CardInView
        id={card.name}
        listTitle={`mainSale_slider`}
        position={idx + 1}
        card={{
          link: card.url,
          title: card.name,
        }}
        cardType='promo'
        className={styles.card}
        targetPathname={card.url}
      >
        <div className={styles.textContainer}>
          <div className={styles.name}>{card.name}</div>
          <SaleSticker>{card.sale}</SaleSticker>
        </div>
        <div className={styles.imgContainer}>
          <Img src={card.image} className={styles.img} />
        </div>
      </CardInView>
    </Link>
  );
};

export default Card;
