import { cn } from '@divlab/divanui';

import styles from './SaleSticker.module.css';

import type { FC, HTMLAttributes } from 'react';

interface StickerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  color?: 'black' | 'white';
}

const Sticker: FC<StickerProps> = (props) => {
  const { className, children, color = 'black', ...restProps } = props;

  return (
    <div
      className={cn(styles.sticker, className, {
        [styles.black]: color === 'black',
        [styles.white]: color === 'white',
      })}
      {...restProps}
    >
      <div className={styles.container}>
        <svg className={styles.svg} viewBox='0 0 29 50' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M25.6465 0C22.5357 0.0619707 19.6711 1.26007 17.8695 3.24313L2.33794 20.4606C-0.112646 23.177 -0.112646 26.8849 2.33794 29.6013L17.8807 46.8188C19.6823 48.8019 22.5469 50 25.6576 50H28.5V0H25.6465ZM18.6424 29.8015C21.4588 29.8015 23.7419 27.5777 23.7419 24.8345C23.7419 22.0913 21.4588 19.8675 18.6424 19.8675C15.8261 19.8675 13.543 22.0913 13.543 24.8345C13.543 27.5777 15.8261 29.8015 18.6424 29.8015Z'
          />
        </svg>

        <div className={styles.text}>{children}</div>
      </div>
    </div>
  );
};

export default Sticker;
