import { memo, useMemo, useState } from 'react';
import { cn, Collapse } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import Section from '@Components/Section';
import useMedias from '@Hooks/useMedias';
import useCountries from '@Hooks/useCountries';
import Button from '@UI/Button';
import IdeasGallery from './elements/IdeasGallery';
import styles from './Ideas.module.css';

import type { HomeIdeaData } from '@Types/Ideas';
import type { FC, HTMLAttributes } from 'react';

// т.к. данные для пинов здесь и на страничке О компании должны иметь одинаковый вид, ждем когда от бэка в пинах будет приходить не продукт, а его ID и меняем в двух местах
export interface IdeasProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  title: string;
  ideas: HomeIdeaData[];
}

const Ideas: FC<IdeasProps> = (props) => {
  const { className, title, ideas } = props;
  const { isMobile, isMobileM } = useMedias();
  const { isBlr } = useCountries();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const withBanner = ideas.length === 11;

  const indexIdeaWithBanner = isMobileM && !isMobile ? 0 : 1;

  const ideasItems = useMemo(() => {
    let count = 6;

    if (isMobile && !withBanner) {
      count = 3;
    }

    if (isMobile && withBanner) {
      count = 2;
    }

    if (!isMobile && withBanner) {
      count = 5;
    }

    return { main: ideas.slice(0, count), secondary: ideas.slice(count) };
  }, [ideas, isMobile, withBanner]);

  return (
    <div className={className}>
      {title && (
        <Section
          className={styles.title}
          title={title}
          typeSection={isBlr ? 'universal' : 'secondary'}
        />
      )}

      <div className={styles.container}>
        <IdeasGallery
          title={title}
          items={ideasItems.main}
          positionOfBanner={withBanner && indexIdeaWithBanner}
        />
        {!isOpen && (
          <div className={styles.buttonWrapper}>
            <Button theme='transparent' onClick={() => setIsOpen(true)} className={styles.button}>
              {t('show-ideas')}
            </Button>
          </div>
        )}
        <Collapse collapsed={!isOpen} className={cn(styles.collapse, { [styles.isOpen]: isOpen })}>
          <IdeasGallery title={title} items={ideasItems.secondary} collapsed={true} />
        </Collapse>
      </div>
    </div>
  );
};

export default memo(Ideas);
