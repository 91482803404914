import { memo } from 'react';
import { cn } from '@divlab/divanui';

import useRequest from '@Hooks/useRequest';
import CrossSaleSection from '@Components/CrossSaleSection';
import SquareProductCard from '@Components/SquareProductCard';
import styles from './ProductsGallery.module.css';

import type { HTMLAttributes, FC } from 'react';
import type { ProductData } from '@Types/Product';

interface ProductsGalleryProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  products: ProductData[];
  analyticsTitle: string;
}

const ProductsGallery: FC<ProductsGalleryProps> = (props) => {
  const { products, analyticsTitle, className, ...restProps } = props;
  const { country } = useRequest();
  const isUZB = country === 'UZB';

  return (
    <div {...restProps} className={cn(className, styles.wrapper)}>
      <CrossSaleSection
        cnGallery={styles.gallery}
        cnGalleryItem={styles.galleryItem}
        analyticsTitle={analyticsTitle}
        products={products}
        renderItem={(productCardProps) => {
          return (
            <SquareProductCard
              className={cn(styles.productItem, {
                [styles.productItemUzb]: isUZB,
              })}
              {...productCardProps}
            />
          );
        }}
      />
    </div>
  );
};

export default memo(ProductsGallery);
