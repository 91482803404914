import { lazy, memo } from 'react';
import { useInView } from 'react-intersection-observer';
import { cn } from '@divlab/divanui';

import Suspense from '@Components/Suspense';
import styles from './ShowroomsMapNew.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { MapData } from '@Types/Map';
import type { SellPointData } from '@Types/SellPoints';

export interface ShowroomsMapProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  map?: MapData;
  selectedPoint?: SellPointData;
}

const MapWithPopup = lazy(() => import('./elems/MapWithPopup'));

const MapPlaceholder: FC = () => {
  return <div className={styles.mapPlaceholder} />;
};

const ShowroomsMapNew: FC<ShowroomsMapProps> = (props) => {
  const { className, map, selectedPoint, ...restProps } = props;
  const [ref, inView] = useInView({
    rootMargin: '100px 0px',
    triggerOnce: true,
  });

  return (
    <div {...restProps} className={cn(styles.section, className)} ref={ref}>
      {map?.title && <div className={styles.title}>{map.title}</div>}
      {map?.description && <div className={styles.text}>{map.description}</div>}

      {inView ? (
        <Suspense fallback={<MapPlaceholder />}>
          <MapWithPopup
            className={styles.mapWithPopup}
            map={map}
            defaultSelectedPoint={selectedPoint}
          />
        </Suspense>
      ) : (
        <MapPlaceholder />
      )}
    </div>
  );
};

export default memo(ShowroomsMapNew);
