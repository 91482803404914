import { memo } from 'react';
import { cn } from '@divlab/divanui';

import useCountries from '@Hooks/useCountries';
import CrossSaleSection from '@Components/CrossSaleSection';
import CrossSaleProductCard from '@Components/CrossSaleProductCard';
import styles from './NewProducts.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { ProductsSectionData } from '@Pages/PageIndex/typings';

export interface NewProductsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  section: ProductsSectionData;
}

const NewProducts: FC<NewProductsProps> = (props) => {
  const { className, section, ...restProps } = props;
  const { isBlr } = useCountries();

  return (
    <div {...restProps} className={cn(styles.wrapper, className)}>
      <div>
        <CrossSaleSection
          cnGallery={styles.gallery}
          title={section.header}
          analyticsTitle={section.header}
          text={section.text}
          products={section.items}
          typeSection={isBlr ? 'universal' : 'secondary'}
          renderItem={(productCardProps) => (
            <div className={styles.productItem}>
              <CrossSaleProductCard {...productCardProps} />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default memo(NewProducts);
